import { Entity } from '@backstage/catalog-model';
import { checkUrl } from '../helpers';
import { parseLocationRef } from '@backstage/catalog-model';
import { format } from 'date-fns-tz';

export function getReleaseUrl(entity: Entity): string | undefined {
  const repoUrl = entity.metadata.annotations?.['backstage.io/source-location'];
  if (repoUrl === undefined) {
    return undefined;
  }
  const url = parseLocationRef(repoUrl).target;
  const branch = entity.metadata.annotations?.['ewii/branch'];
  const encodedBranch = encodeURI(branch || '');
  return `${url}?version=GB${encodedBranch}`.trim();
}

export function getBuildUrl(entity: Entity): string | undefined {
  const buildUrl = entity.metadata.annotations?.['ewii/build-url'];
  if (buildUrl === undefined) {
    return undefined;
  }
  return buildUrl;
}

export function getBuildText(entity: Entity): string | undefined {
  return entity.metadata.annotations?.['ewii/build'];
}

export function getCommitId(entity: Entity): string | undefined {
  return entity.metadata.annotations?.['ewii/commit']?.slice(0, 7);
}

export function getCommitUrl(entity: Entity): string | undefined {
  const repoUrl = entity.metadata.annotations?.['backstage.io/source-location'];
  if (repoUrl === undefined) {
    return undefined;
  }
  const url = parseLocationRef(repoUrl).target;
  const commit = entity.metadata.annotations?.['ewii/commit'];
  return `${url}/commit/${commit}`.trim();
}

export function getReleaseText(entity: Entity): string | undefined {
  return entity.metadata.annotations?.['ewii/branch'];
}

export function getBuildDate(entity: Entity): string | undefined {
  const buildDate = entity.metadata.annotations?.['ewii/build-date'];
  if (buildDate === undefined) {
    return undefined;
  }
  const copenhagenTimezone = 'Europe/Copenhagen';
  const formattedDate = format(new Date(buildDate), 'yyyy.MM.dd HH:mm', {
    timeZone: copenhagenTimezone,
  });

  return formattedDate;
}

export function containsBuildInfo(entity: Entity): boolean {
  const buildUrl = entity.metadata.annotations?.['ewii/build-url'];
  const build = entity.metadata.annotations?.['ewii/build'];
  return !!build && !!buildUrl && checkUrl(buildUrl);
}

export function containsReleaseInfo(entity: Entity): boolean {
  const branch = entity.metadata.annotations?.['ewii/branch'];
  return !!branch;
}
