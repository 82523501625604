import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 35,
    height: 28,
  },
  path1: {
    fill: '#232323',
  },
  path2: {
    fill: 'url(#linearGradient-green)',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg 
    xmlns="http://www.w3.org/2000/svg"
    className={classes.svg}
    viewBox="0 0 112 50">
      <defs>
        <linearGradient x1="100%" y1="57.73%" x2="0%" y2="42.27%" id="linearGradient-green">
          <stop stopColor="#18CCB4" offset="0%"></stop><stop stopColor="#0AA0AA" offset="100%">
          </stop>
        </linearGradient>
      </defs>
      <g id="Assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1427 -1286)" fillRule="nonzero" id="Group">
          <g transform="translate(1427 1286)" id="Logo">
            <path className={classes.path1} d="M7.64 19.19L0 49.941h26.937l1.961-7.864H11.905l.974-3.835h15.574l1.82-7.34H14.72l.974-3.834h16.928l1.968-7.865-26.95-.013zm62.576 0l-8.171 17.87h-.09l.525-17.87h-8.688l-8.26 17.87h-.09l.522-17.87h-9.776l1.454 30.751h9.6l7.482-15.932h.09L54.34 49.94h9.601l16.414-30.75H70.216zm12.687 0L75.29 49.941h9.943l7.599-30.744-9.93-.007zm15.066 0L90.36 49.941h9.944l7.688-30.744-10.023-.007z" 
            id="Combined-Shape"></path>
            <path className={classes.path2} d="M89.553.176c3.1 0 5.612 2.47 5.612 5.516 0 3.047-2.513 5.517-5.612 5.517-3.1 0-5.612-2.47-5.612-5.517 0-3.046 2.513-5.516 5.612-5.516zm16.835 0c3.1 0 5.612 2.47 5.612 5.516 0 3.047-2.512 5.517-5.612 5.517s-5.612-2.47-5.612-5.517c0-3.046 2.513-5.516 5.612-5.516z" 
            id="Combined-Shape">
            </path>
          </g>
        </g>
      </g>
  </svg>
  );
};

export default LogoIcon;
