import React from 'react';
import { Link } from '@backstage/core-components';
import { CatalogTableRow } from '@backstage/plugin-catalog';
import { TableColumn } from '@backstage/core-components';
import { Entity } from '@backstage/catalog-model';
import { checkUrl } from './../helpers';
import {
  containsBuildInfo,
  containsReleaseInfo,
  getBuildText,
  getReleaseText,
  getReleaseUrl,
} from './CatalogHelpers';

export function createBuildInfoColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Build',
    field: 'entity.metadata.annotations',
    render: ({ entity }: { entity: Entity }) => {
      if (containsBuildInfo(entity)) {
        const href = entity.metadata.annotations?.['ewii/build-url'];
        return (
          <Link to={href!} target="_blank" rel="noopener">
            {getBuildText(entity) || href}
          </Link>
        );
      }
      return 'Ukendt';
    },
  };
}

export function createReleaseInfoColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Release',
    field: 'entity.metadata.annotations.ewii/branch',
    render: ({ entity }: { entity: Entity }) => {
      if (containsReleaseInfo(entity)) {
        const href = getReleaseUrl(entity);
        const branch = getReleaseText(entity);
        return (
          <Link to={href!} target="_blank">
            {branch || href}
          </Link>
        );
      }
      return 'Ukendt';
    },
  };
}
