import {
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  createUnifiedTheme,
} from '@backstage/theme';

const pageThemesOverride: Record<string, PageTheme> = {};

const linearGradient = 'linear-gradient(279deg, #19c8af, #0aa0aa)';

Object.keys(defaultPageThemes).map(key => {
  pageThemesOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: '#000000',
    backgroundImage: linearGradient,
  };
});

export const Ewii = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#00afaa',
        light: '#bef0e6',
        dark: '#007d82',
      },
      navigation: {
        ...palettes.light.navigation,
        selectedColor: '#007d82',
        background: '#e6f5f5',
        color: '#232323',
        indicator: '#007d82',
        navItem: {
          hoverBackground: linearGradient,
        },
      },
    },
  }),
  pageTheme: pageThemesOverride,
  defaultPageTheme: 'home',
});
