import {
  HomePageCompanyLogo,
  HomePageStarredEntities,
  TemplateBackstageLogo,
  HomePageToolkit,
  HomePageTopVisited,
  HomePageRecentlyVisited,
} from '@backstage/plugin-home';
import { Content, Page, InfoCard } from '@backstage/core-components';
import { HomePageSearchBar, searchPlugin } from '@backstage/plugin-search';
import {
  SearchContextProvider,
} from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import {Link, Description} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  grid: {
    background: 'linear-gradient(300deg, #04dcba, #0aa0aa)',
    height: '100vh'
  }
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#232323',
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { svg, path, container } = useLogoStyles();

  return (
    <SearchContextProvider >
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6} classes={{
            root: classes.grid
          }}>
            <HomePageCompanyLogo
              className={container}
              logo={<TemplateBackstageLogo classes={{ svg, path }} />}
            />
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={3}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={3}>
              <HomePageToolkit
                    tools={[
                        {
                            url: 'https://trefor.sharepoint.com/sites/DATA-Governance-Wiki/SitePages/Governance/Introduktion.aspx',
                            label: 'Governance Wiki',
                            icon: <Link />,
                        },
                        {
                            url: 'https://trefor.sharepoint.com/sites/DATA-Governance-Wiki/SitePages/Data%20Governance/Datastandarder.aspx',
                            label: 'Datastandarder',
                            icon: <Description />,
                            }
                        ]}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <HomePageTopVisited />
            </Grid>
            <Grid item xs={12} md={3}>
                <HomePageRecentlyVisited />
            </Grid>

              {/* <Grid item xs={12} md={6}>
                <FeaturedDocsCard
                    filter={{
                    'spec.type': 'documentation',
                    'metadata.name': 'getting-started-with-backstage',
                    }}
                />
                </Grid> */}
                
              {/* <Grid item xs={12} md={6}>
                <InfoCard title="Composable Section">
                  <div style={{ height: 370 }} />
                </InfoCard>
              </Grid> */}
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
